import React, { useState } from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import WaitlistForm from './WaitlistForm';

function HeroSection() {
  const [openForm, setOpenForm] = useState(false);

  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        color: 'text.primary',
        py: 8,
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography variant="h1">Start a New Career in IT</Typography>
        <Typography variant="h5" sx={{ my: 2 }}>
          Learn how to test apps/programs and become a QA Engineer.
        </Typography>
        <Button variant="contained" color="primary" size="large" onClick={handleOpenForm}>
          JOIN WAITLIST!
        </Button>
        <WaitlistForm open={openForm} onClose={handleCloseForm} />
      </Container>
    </Box>
  );
}

export default HeroSection;
