import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',  // Set dark mode
    primary: {
      main: '#FFD700', // Bright yellow for buttons and highlights
    },
    background: {
      default: '#000000', // Black background
      paper: '#121212', // Darker gray for card backgrounds
    },
    text: {
      primary: '#FFFFFF', // White for primary text
      secondary: '#AAAAAA', // Gray for secondary text
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: '#FFD700', // Use primary color for headlines
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
  },
});

export default theme;
