// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';

function Header() {
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          QA Bro
        </Typography>
        <Button color="inherit">About Us</Button>
        <Button color="inherit">Courses</Button>
        <Button color="inherit">Call Experts</Button>
        <Button color="inherit">Offers</Button>
        <Button color="inherit">Reviews</Button>
        <Button color="inherit">Prices</Button>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
