// src/App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import KeyBenefits from './components/KeyBenefits';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header /> {/* This displays the navigation bar */}
      <HeroSection />
      <KeyBenefits />
    </ThemeProvider>
  );
}

export default App;
