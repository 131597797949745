// src/components/KeyBenefits.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

function KeyBenefits() {
  return (
    <Container sx={{ my: 5 }}>
      <Typography variant="h2" gutterBottom align="center" color="primary">
        Why Choose QA School?
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ minHeight: 200, textAlign: 'center' }}>
            <CardContent>
              <StarIcon fontSize="large" color="primary" />
              <Typography variant="h6" color="primary" gutterBottom>
                Earn $95K+ / Year
              </Typography>
              <Typography>Become a QA Engineer by testing programs and reporting issues.</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ minHeight: 200, textAlign: 'center' }}>
            <CardContent>
              <SchoolIcon fontSize="large" color="primary" />
              <Typography variant="h6" color="primary" gutterBottom>
                Fast-track Career
              </Typography>
              <Typography>Start your new career in just 2 months with our intensive courses!</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ minHeight: 200, textAlign: 'center' }}>
            <CardContent>
              <WorkIcon fontSize="large" color="primary" />
              <Typography variant="h6" color="primary" gutterBottom>
                Interactive Learning
              </Typography>
              <Typography>Our online courses are engaging and hands-on for maximum impact.</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ minHeight: 200, textAlign: 'center' }}>
            <CardContent>
              <SupportAgentIcon fontSize="large" color="primary" />
              <Typography variant="h6" color="primary" gutterBottom>
                Job Placement Support
              </Typography>
              <Typography>Get interview prep and job placement assistance to launch your career.</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default KeyBenefits;
